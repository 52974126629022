import { createContext } from 'react';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, getDoc, doc, query, where } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Need to be fixed
const firebaseConfig = {
    apiKey: 'AIzaSyDP_V20kTpf1U4pkqqcmKvsi5A9egrZjoQ',
    authDomain: 'thermal-hour-337716.firebaseapp.com',
    projectId: 'thermal-hour-337716',
    storageBucket: 'thermal-hour-337716.appspot.com',
    messagingSenderId: '939850463000',
    appId: '1:939850463000:web:ac73067a4f39fa0caf106d',
};

const landisfirebaseConfig = {
    apiKey: 'AIzaSyA0ZrMt_lbNAEXXW6CRUGPWNZ0WoC0tKFM',
    authDomain: 'tessellcp.firebaseapp.com',
    projectId: 'tessellcp',
    storageBucket: 'tessellcp.appspot.com',
    messagingSenderId: '827683875758',
    appId: '1:827683875758:web:f14229890d5dd0c65af63f',
};

export const NetworkConnectionContext = createContext<{
    db: any;
    auth: any;
    functions: any;
    fetchData: any;
    fetchRowData: any;
    fetchDataByKeyValue: any;
}>(null);

export const GCPNetworkConnection = (props) => {
    const { children } = props;

    // Need to be fixed
    const app = initializeApp(
        window.location.origin.includes('landisgyr') ? landisfirebaseConfig : firebaseConfig,
    );

    // Initialize Firestore

    const db = getFirestore(app, 'tessell');

    const auth = getAuth(app);
    const functions = getFunctions(app, 'us-east1');

    const fetchData = async (collectionName) => {
        const querySnapshot = await getDocs(collection(db, collectionName));
        const dataList = querySnapshot.docs.map((doc) => doc.data());
        return dataList;
    };

    const fetchRowData = async (collectionName, id) => {
        const querySnapshot = await getDoc(doc(db, collectionName, id));
        return querySnapshot.data();
    };

    const fetchDataByKeyValue = async (collectionName, key, value) => {
        const machinesRef = collection(db, collectionName);
        const q = query(machinesRef, where(key, '==', value));
        const querySnapshot = await getDocs(q);
        const dataList = querySnapshot.docs.map((doc) => doc.data());
        return dataList;
    };

    return (
        <NetworkConnectionContext.Provider
            value={{
                db,
                auth,
                functions,
                fetchData,
                fetchRowData,
                fetchDataByKeyValue,
            }}
        >
            {children}
        </NetworkConnectionContext.Provider>
    );
};
