/* eslint-disable import/prefer-default-export */
/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */

import {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
    blueGrey,
} from '@mui/material/colors';

const palettes = [
    blue,
    indigo,
    cyan,
    teal,
    lightBlue,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    red,
    pink,
    purple,
    deepPurple,
    brown,
    grey,
    blueGrey,
];

const tessellBlue = {
    dark: '#11567F',
    light: '#1E7EA1',
    hover: '#29B5E8',
};

export const styles = {
    color: {
        darkShade: tessellBlue.dark,
        lightShade: tessellBlue.light,
        hover: tessellBlue.hover,
        darkBorder: '#004D73',
        lighterShade: 'rgba(30, 126, 161, 0.4)',
        lighterShade2: '#1E7EA1',
        lighterShade3: 'rgba(30, 126, 161, 0.1)',
        header: '#11567F',
        baseBackground: '#FFFFFF',
        greyBackground4: '#F7F7F7',
        greyBackgroundF9: '#F9F9F9',
        greyE6: '#E6E6E6',
        greyBackground: '#EEEEEE',
        greyBackgroundE6: '#E6E6E6',
        greyBackgroundDD: '#DDDDDD',
        greyBackgroundCC: '#CCCCCC',
        greyBackgroundFA: '#FAFAFA',
        greyBackgroundFC: '#FCFCFC',
        greyBackgroundBD: '#BDBDBD',
        greyBackground52: '#52525A',
        greyBackgroundF8: '#F8FAFC',
        primaryBackgroundLight: '#F4F9FA',
        primaryBackgroundLight2: '#E3F3FD',
        monotoneLightLightC0: '#c0c0c0',
        monotoneLightDarkF0: '#f0f0f0',
        monotoneDarkDark66: '#666666',
        monotoneDarker22: '#222222',
        greyBackground97: '#979797',
        grey3F: '#3F3F45',
        monotoneDarkLight99: '#999999',
        red: '#EC373C',
        redBackground: '#FCF0F0',
        warningBackground: '#FAE5E5',
        warningBackgroundFE: '#FEF2D8',
        lighterRed: '#EC373CBD',
        green: '#4DAC4A',
        greenBackground: '#F0FCF4',
        warn: '#F29132',
        textBoxLabel: '#08080D',
        lightBlueBackground: '#F2FAFE',
        oceanBlueSteel: '#21496B',
        winterSky: '#F4F9FA',
        skyBlue: '#117DBE',
        black: '#000000',
        lightGrey: '#979797',
        lightBlack: '#08080D',
        neutral: '#666',
        gray900: '#18181B',
        primary1: '#3986C1',
        grayscale: '#A1A1A9',
        greyE4: '#E4E4E7',
        lightBackground: '#E7F6FE',

        // v2 color palate
        baseBackgroundV2: '#F5F5F5',
        OffWhite: '#F8F8F8',
        yellow: yellow[200],
        getRedErrorBackgroundWithOpacity: (opacity: number) => `rgba(236, 55, 60, ${opacity})`,
    },
    contentBox: {
        boxSizing: 'border-box',
        borderRadius: '10px',
        boxShadow: '0px 3px 10px rgba(8, 8, 13, 0.25) !important',
        background: 'white',
    },
    textBoxLabel: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '19px',
        color: '#08080D',
    },
    protection: [
        {
            name: 'Gold',
            darkShade: '#FFD900',
            lightShade: '#FAFAD2',
        },
        {
            name: 'Silver',
            darkShade: '#c0c0c0',
            lightShade: '#e8e8e8',
        },
        {
            name: 'Bronze',
            darkShade: '#cd7f32',
            lightShade: '#edbd97',
        },
        {
            name: 'Brass',
            darkShade: '#B5A642',
            lightShade: '#d9d19e',
        },
        {
            name: 'Default',
            darkShade: tessellBlue.dark,
            lightShade: tessellBlue.light,
        },
    ],
    palettes,
    dialog: {
        headerBackground: '#F8F8F8',
    },
    fontFamily: {
        primary: 'Lato, sans-serif',
    },
};
