import clsx from 'clsx';
import { useStyles } from './utils';

export default function StartStopMonotoneIcon(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="59"
            height="24"
            viewBox="0 0 59 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15.6 7.2H8.4C8.08174 7.2 7.77652 7.32643 7.55148 7.55147C7.32643 7.77651 7.2 8.08174 7.2 8.4V15.6C7.2 15.9183 7.32643 16.2235 7.55148 16.4485C7.77652 16.6736 8.08174 16.8 8.4 16.8H15.6C15.9183 16.8 16.2235 16.6736 16.4485 16.4485C16.6736 16.2235 16.8 15.9183 16.8 15.6V8.4C16.8 8.08174 16.6736 7.77651 16.4485 7.55147C16.2235 7.32643 15.9183 7.2 15.6 7.2ZM14.4 14.4H9.6V9.6H14.4V14.4ZM12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21508 0.913451 7.4078C0.00519941 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.807 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0865C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6934 24 14.3734 24 12C24 10.4241 23.6896 8.8637 23.0866 7.4078C22.4835 5.95189 21.5996 4.62902 20.4853 3.51472C19.371 2.40041 18.0481 1.5165 16.5922 0.913445C15.1363 0.310389 13.5759 0 12 0ZM12 21.6C10.1013 21.6 8.24524 21.037 6.66653 19.9821C5.08782 18.9272 3.85736 17.4279 3.13076 15.6738C2.40416 13.9196 2.21405 11.9893 2.58447 10.1271C2.95488 8.26491 3.86919 6.55436 5.21178 5.21177C6.55436 3.86919 8.26492 2.95488 10.1271 2.58446C11.9894 2.21404 13.9196 2.40415 15.6738 3.13076C17.4279 3.85736 18.9272 5.08781 19.9821 6.66652C21.037 8.24524 21.6 10.1013 21.6 12C21.6 14.5461 20.5886 16.9879 18.7882 18.7882C16.9879 20.5886 14.5461 21.6 12 21.6Z"
                fill="black"
            />
            <path
                d="M56.1482 8.39915L44.5562 1.75115C43.9289 1.38894 43.217 1.1992 42.4926 1.20119C41.7683 1.20318 41.0574 1.39683 40.4322 1.76248C39.8069 2.12813 39.2895 2.65275 38.9327 3.28304C38.5758 3.91334 38.392 4.62687 38.4002 5.35115V18.6951C38.4002 19.7836 38.8325 20.8275 39.6022 21.5971C40.3718 22.3668 41.4157 22.7991 42.5042 22.7991C43.2247 22.7979 43.9323 22.6076 44.5562 22.2471L56.1482 15.5991C56.771 15.2387 57.2881 14.7207 57.6477 14.0973C58.0072 13.4738 58.1964 12.7668 58.1964 12.0471C58.1964 11.3275 58.0072 10.6205 57.6477 9.99703C57.2881 9.37359 56.771 8.85564 56.1482 8.49515V8.39915ZM54.9482 13.4271L43.3562 20.1711C43.0963 20.3184 42.8028 20.3958 42.5042 20.3958C42.2055 20.3958 41.912 20.3184 41.6522 20.1711C41.3931 20.0216 41.1779 19.8064 41.0284 19.5473C40.8788 19.2882 40.8001 18.9943 40.8002 18.6951V5.30315C40.8001 5.00399 40.8788 4.71008 41.0284 4.45099C41.1779 4.19189 41.3931 3.97673 41.6522 3.82715C41.913 3.68214 42.2057 3.60382 42.5042 3.59915C42.8024 3.60527 43.0947 3.6835 43.3562 3.82715L54.9482 10.5232C55.2073 10.6727 55.4226 10.8878 55.5722 11.1469C55.7219 11.406 55.8007 11.6999 55.8007 11.9991C55.8007 12.2984 55.7219 12.5923 55.5722 12.8514C55.4226 13.1105 55.2073 13.3256 54.9482 13.4751V13.4271Z"
                fill="black"
            />
        </svg>
    );
}
