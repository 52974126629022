import userflow from 'userflow.js';
import { INIT_KEYS as KEYS, ID_KEYS } from './constants';

class GuidanceManagerClass {
    userFlow = userflow;

    initialise(isProd) {
        return this.userFlow.init(isProd ? KEYS.prod_key : KEYS.dev_key);
    }

    identifyUser(userKey, userObject, options) {
        return this.userFlow.identify(userKey, userObject, options);
    }

    identifyGroup(groupKey, groupObject, options) {
        return this.userFlow.group(groupKey, groupObject, options);
    }

    reset() {
        return this.userFlow.reset();
    }

    track(eventName, attributes) {
        return this.userFlow.track(eventName, { attributes });
    }

    // eslint-disable-next-line class-methods-use-this
    setIdKey(isProd) {
        return isProd ? ID_KEYS.prod_key : ID_KEYS.dev_key;
    }

    setVisible(param) {
        return this.userFlow.setResourceCenterLauncherHidden(param);
    }
}

const GuidanceManager = new GuidanceManagerClass();

export default GuidanceManager;
