import clsx from 'clsx';
import { useStyles } from './utils';

export default function PerfInfoCard(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="149"
            height="118"
            viewBox="0 0 149 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M33.9383 62.23C34.0191 51.4632 38.3737 41.1696 46.0442 33.6134C53.7146 26.0573 64.0725 21.8578 74.8393 21.9386L74.5345 62.5348L33.9383 62.23Z"
                fill="#08080D"
            />
            <path
                d="M115.131 62.8629C115.087 68.194 113.995 73.4645 111.915 78.3733C109.835 83.2822 106.808 87.7333 103.008 91.4725C99.2081 95.2118 94.7087 98.1659 89.7669 100.166C84.825 102.167 79.5376 103.174 74.2064 103.131L74.5345 62.5348L115.131 62.8629Z"
                fill="#08080D"
            />
            <path
                d="M77.0411 14.8456C82.9911 14.895 88.873 16.1159 94.3511 18.4386C99.8292 20.7612 104.796 24.1401 108.968 28.3823C113.141 32.6245 116.437 37.647 118.668 43.1629C120.899 48.6789 122.022 54.5803 121.973 60.5302L76.6645 60.1536L77.0411 14.8456Z"
                fill="white"
            />
            <path
                d="M74.2304 103.131C68.8992 103.091 63.6281 102.001 58.7181 99.9242C53.808 97.8471 49.3552 94.8232 45.6137 91.0252C41.8723 87.2273 38.9156 82.7295 36.9124 77.7889C34.9092 72.8482 33.8987 67.5613 33.9387 62.2302L74.535 62.5347L74.2304 103.131Z"
                fill="#08080D"
            />
        </svg>
    );
}
