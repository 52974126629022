/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-debugger */
/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import {
    Typography,
    Box,
    Button,
    CircularProgress,
    InputAdornment,
    IconButton,
    Stack,
    Zoom,
    Fade,
    LinearProgress,
    Toolbar,
    TextField,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import jwt_decode from 'jwt-decode';
import {
    OAuthProvider,
    signInWithPopup,
    signInWithEmailAndPassword,
    setPersistence,
    browserLocalPersistence,
} from 'firebase/auth';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getUIBuildTimestamp } from 'api-clients/misc-apis';
import {
    TessellIcon,
    GoogleIcon,
    MicrosoftIcon,
    LoginBanner,
    OktaShortIcon,
    TessellLogoWithTextIcon,
} from 'icons-v2';
import { update } from 'redux/userSlice';
import {
    checkWhoami,
    getTenantInfo,
    useLogin,
    getMSlogin,
    useGetLoginOptions,
} from 'api-clients/loginServices';
import Cache from 'tools/Cache';
import Notification from 'common-components/Notification';
import TessellInput from 'common-components/TessellInput';
import SupportUtil from 'tools/SupportUtil';
import { styles } from 'styles/style-templates';
import AppUtil from 'tools/AppUtil';
import { oauthUrl } from 'constants/config';
import ForgotPasswordDialog from './ForgotPasswordDialog';
import { isUserLoggedIn, tget } from '../../utils/general';
import { Buffer } from 'buffer';
import useFeatureFlags from 'customHooks/useFeatureFlags';
import { signIn } from 'authentication/signIn';
import {
    useSetUserLoginInfoContext,
    useUserLoginInfoContext,
} from 'authentication/UserLoginInfoContext';
import { NetworkConnectionContext } from 'network-context/NetworkConnectionContext';

window.Buffer = window.Buffer || Buffer;

const useStyles = () => {
    const theme = useTheme();
    return {
        content: {
            [theme.breakpoints.down('laptop')]: {
                width: '300px',
            },
            [theme.breakpoints.up('laptop')]: {
                width: '100%',
                marginTop: 8,
            },
        },
        blank: {
            [theme.breakpoints.down('laptop')]: {
                width: '15%',
            },
            [theme.breakpoints.up('laptop')]: {
                width: '25%',
            },
        },
        // banner: {
        //   [theme.breakpoints.up('laptop')]: {
        //     marginLeft: 12,
        //   },
        // },
        oauthButtons: {
            boxSizing: 'borderBox',
            /* Auto layout */

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px',
            gap: '16px',

            height: '36px',

            /* Neutrals/N6 */

            background: '#FFFFFF',
            /* Neutrals/N5 */

            border: '1px solid #EEEEEE',
            /* DS-Base */

            boxShadow: '0px 1px 4px rgba(31, 41, 55, 0.08)',
            borderRadius: '4px',

            /* Inside auto layout */

            flex: 'none',
            order: '0',
            alignSelf: 'stretch',
            flexGrow: '0',
            '&:hover': {
                border: '1px solid #11567F !important',
                color: '#11567F !important',
                background: '#FFFFFF !important',
            },
        },
        form: {
            mx: 'auto',
            background: styles.color.baseBackground,
            width: '520px',
            zIndex: '0',
        },
        login: {
            width: '200px !important',
        },
        input: {
            marginTop: theme.spacing(2),
        },
        progress: {
            color: 'white',
        },
        link: {
            textDecoration: 'inherit',
        },
        body: {
            height: '94vh',
            background: styles.color.baseBackground,
        },
        error: {
            color: 'red',
        },
        icon: {
            width: '100px',
            height: '100px',
            position: 'absolute',
            top: '0',
            mx: 'auto',
            borderRadius: '50%',
            background: 'white',
        },
    };
};
const PasswordExpiryErrorMessage =
    'Your password has expired. Please update your password to maintain the security of your account.';

export default function Login() {
    // * we get the setter function for the servers app feature flag from the below hook
    const setUserLoginInfo = useSetUserLoginInfoContext();
    const { serversApp } = useFeatureFlags();
    const { updateIsServersAppAvailable } = serversApp;

    const [data, setData] = useState({
        emailId: '',
        password: '',
    });
    // useEffect(() => {
    //   if (Cache.get('reload') && Cache.get('reload') !== 'null') {
    //     Cache.clear();
    //     window.location.replace(window.location.href);
    //   }
    // }, [Cache.get('reload')]);

    useEffect(() => {
        getUIBuildTimestamp().then((res) => {
            res.json().then((buildDtata) => {
                if (buildDtata?.buildTimestamp) {
                    Cache.set('buildTimestamp', buildDtata?.buildTimestamp);
                }
            });
        });
    }, []);

    const history = useHistory();

    const { deleteUrlParams } = AppUtil;
    const uri = Cache.get('redirect-url');
    const token = Cache.get('accessToken');
    const decodedToken = token && token !== 'undefined' && jwt_decode(token);
    const expiryTime = decodedToken ? tget(decodedToken, 'exp', 0) * 1000 : 0;
    const urlParams = new URLSearchParams(window.location.search);
    const googleData = urlParams.get('googleData');
    // const signUpToke = urlParams.get('token');
    const [cachedGoogleData, setCachedGoogleData] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [googleSubmitted, setGoogleSubmitted] = useState(false);
    const [notify, setNotify] = useState(false);
    const [message, setMessage] = useState('');
    const [severity, setSeverity] = useState('');
    const [multiTenant, setMultitenant] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [multiTenantData, setMultitenantData] = useState({});
    const [redirectUrl, setRedirectUrl] = useState(false);
    const [done, setDone] = useState(false);
    const [loginOptions, setLoginOptions] = useState([]);
    const [showForgot, setShowForgot] = useState(false);
    const [passwordExpired, setPasswordExpired] = useState(false);

    const [errMessage, setErrMessage] = useState('');

    const { postData, response, responseStatus } = useLogin();

    const { response: loginOptionsRes, responseStatus: loginOptionsResStatus } =
        useGetLoginOptions();

    const classes = useStyles();
    const dispatch = useDispatch();
    const disableLogin = !data.emailId.length || !data.password.length;
    const updateValue = (e, name) => {
        setData((oldData) => {
            const value = e && e.target ? e.target.value : e;
            return { ...oldData, [name]: value };
        });
    };

    const navigate = (route) => {
        history.push(route);
    };
    const { auth } = useContext(NetworkConnectionContext);

    // const submit = (event) => {
    //   if (event) {
    //     event.preventDefault();
    //   }
    //   const EnvConfig = JSON.parse(Cache.get('envConfig') || `{}`);
    //   if (false) {
    //     signIn(
    //       data.emailId,
    //       data.password,
    //       setUserLoginInfo,
    //       navigate,
    //       setSubmitted,
    //       setErrMessage,
    //     );
    //   } else {
    //     const emailid = 'bala@tessell-demo.com';
    //     Cache.set('email', emailid);
    //     if (uri && uri !== '/login') {
    //       Cache.set('redirect-url', uri);
    //     }
    //     if (!disableLogin) {
    //       const payload = {
    //         emailId: emailid,
    //         password: data.password,
    //       };
    //       postData(JSON.stringify(payload));
    //       setSubmitted(true);
    //     } else {
    //       setMessage('Invalid credentials');
    //       setSeverity('error');
    //       setNotify(true);
    //     }
    //   }
    // };

    const submit = async (event) => {
        event?.preventDefault();
        setSubmitted(true);
        try {
            const userSignIn = await signInWithEmailAndPassword(auth, data.emailId, data.password);
            setSubmitted(false);
            setUserLoginInfo(userSignIn.user);
        } catch (err) {
            setSubmitted(false);
            setMessage('Invalid credentials');
            setSeverity('error');
            setNotify(true);
        }
    };

    const loginInfo = useUserLoginInfoContext();

    useEffect(() => {
        if (Object.keys(loginInfo)?.length !== 0) {
            if (loginInfo?.accessToken) {
                navigate('/apps/db-services/my-services');
                // navigate('/apps/getting-started/welcome');
            }
        }
    }, [loginInfo]);

    useEffect(() => {
        if (errMessage) {
            setMessage(errMessage);
            setSeverity('error');
            setNotify(true);
        }
    }, [errMessage]);

    useEffect(() => {
        // we check if user has already logged in another tab.
        const handleValidToken = (e) => {
            if (isUserLoggedIn(e)) {
                setRedirect(true);
            }
        };
        window.addEventListener('storage', handleValidToken);
        return function cleanup() {
            window.removeEventListener('storage', handleValidToken);
        };
    }, []);

    const saveResData = (resData, headers) => {
        const whoami = resData;
        Cache.set('email', resData.emailId);
        Cache.set('user', `${resData.firstName} ${resData.lastName}`);

        if (resData.tenantUserAttributes?.length > 1) {
            if (resData?.accessToken?.length) {
                const hdrs = {
                    ...headers,
                    'tenant-id': resData.tenantUserAttributes[0].tenantId,
                };
                getTenantInfo(hdrs).then((r) => {
                    r.json().then((data) => {
                        whoami.customerType = data.customerType;
                        whoami.billingDisabled = data.billingDisabled;
                        Cache.set('enabledClouds', JSON.stringify(data.enabledClouds));
                        SupportUtil.initAnalytics(whoami, true);
                    });
                });
            }

            setMultitenantData(resData);
            setMultitenant(true);
            setRedirect(true);
        } else {
            const hdrs = {
                ...headers,
                'tenant-id': resData.tenantUserAttributes[0].tenantId,
            };
            getTenantInfo(hdrs).then((r) => {
                r.json().then((data) => {
                    whoami.customerType = data.customerType;
                    whoami.billingDisabled = data.billingDisabled;
                    Cache.set('enabledClouds', JSON.stringify(data.enabledClouds));
                    SupportUtil.initAnalytics(whoami, true);
                });
            });

            if (resData.tenantUserAttributes) {
                SupportUtil.initAnalytics(whoami, true);
                if (!Cache.get('persona')?.length || Cache.get('persona') === 'null') {
                    saveUserData(resData);
                } else {
                    // NOTE :- Have added this line as we need to over ride the
                    // userDATA using the whoami call.
                    saveUserData(resData);
                    setRedirect(true);
                }
            } else {
                saveUserData(resData);
            }
            setRedirect(true);
        }
    };

    // eslint-disable-next-line no-shadow
    const saveUserData = (response) => {
        const apps = [];
        let personas = [];
        const features = {};
        response.tenantUserAttributes[0].personas.forEach((p) => {
            personas.push(p.name);
            if (p.name === 'Tessell Operations Administrator') {
                apps.push('Task');
            } else {
                p.apps.forEach((app) => {
                    apps.push(app.name);
                    features[app.name] = app?.features?.map((f) => f.name);
                });
            }
        });
        personas = Array.from(new Set(personas));
        const ctxData = {
            accessToken: response.accessToken,
            apps,
            personas,
            subscriptions: response?.subscriptions,
        };
        Cache.set('email', response.emailId || data.emailId);
        Cache.set('user', `${response.firstName} ${response.lastName}`);
        Cache.set('persona', response.tenantUserAttributes[0].personas[0].name);
        Cache.set('subscription', response.subscriptions && response.subscriptions[0]);
        Cache.set('tenant-id', response.tenantUserAttributes[0].tenantId);
        Cache.set('tenantEmail', response.tenantUserAttributes[0].tenantEmail);
        Cache.set('tenantType', response.tenantUserAttributes[0].tenantType);
        Cache.set('tenantServicePlan', response.tenantUserAttributes[0].tenantServicePlan);
        Cache.set('tenant-created-date', response.tenantUserAttributes[0].tenantDateCreated);
        Cache.set('features', JSON.stringify(features));
        Cache.set('session-id', uuidv4());
        dispatch(update(ctxData));
        return ctxData;
    };

    const saveGenericData = (e) => {
        const userProfile = returnProfileData(e);
        Cache.set('email', userProfile?.email);
        Cache.set('img', userProfile?.picture);
        Cache.set('user', userProfile?.name);
    };

    const setFeatureFlags = ({ whoamiData }) => {
        const appsArray = whoamiData?.tenantUserAttributes?.[0]?.personas?.[0]?.apps;
        const isServersAppAvailable = appsArray.find((item) => item.name === 'Servers');
        updateIsServersAppAvailable(!!isServersAppAvailable);
    };

    const whoamiCall = (headers) => {
        checkWhoami(headers).then((res) => {
            res.json()
                .then((whoamiData) => {
                    if (res.status > 201) {
                        Cache.clear();
                        setMessage(whoamiData?.message);
                        setSeverity('error');
                        setNotify(true);
                        setGoogleSubmitted(false);
                    } else {
                        saveResData(whoamiData, headers);
                        // TODO -- set the feature flags here
                        setFeatureFlags({
                            whoamiData,
                        });
                    }
                    // eslint-disable-next-line no-shadow
                })
                .catch((e) => {
                    setGoogleSubmitted(false);
                    setMessage(e?.message);
                    setSeverity('error');
                    setNotify(true);
                });
        });
    };

    const returnProfileData = (response) => {
        const base64Payload = response.credential.split('.')[1]; // value 0 -> header, 1 -> payload, 2 -> VERIFY SIGNATURE
        const tempPayload = Buffer.from(base64Payload, 'base64');
        const userProfile = JSON.parse(tempPayload);
        return userProfile;
    };

    const googleLogin = (e) => {
        const userProfile = returnProfileData(e);
        const payload = {
            emailId: userProfile?.email,
            password: e?.credential,
            authType: 'GOOGLE',
        };
        postData(JSON.stringify(payload));
        saveGenericData(e);
        setSubmitted(true);
        // setGoogleSubmitted(true);
        Cache.remove('googleData');
    };

    useEffect(() => {
        if (loginOptionsResStatus === 200 && loginOptionsRes?.response?.length) {
            setLoginOptions(loginOptionsRes?.response);
        }
    }, [loginOptionsRes, loginOptionsResStatus]);

    useEffect(() => {
        const cachedGoogleData = Cache.get('googleData');
        if (cachedGoogleData && cachedGoogleData !== 'null') {
            googleLogin(JSON.parse(atob(cachedGoogleData)));
        }
    }, [Cache.get('googleData')]);

    useEffect(() => {
        if (
            (!cachedGoogleData || cachedGoogleData === 'null') &&
            googleData &&
            googleData !== 'null'
        ) {
            setCachedGoogleData(googleData);
        }
    }, [googleData, cachedGoogleData]);

    useEffect(() => {
        const listener = (event) => {
            if (
                (event.code === 'Enter' || event.code === 'NumpadEnter') &&
                data.emailId.length > 1 &&
                data.password.length > 1
            ) {
                event.preventDefault();
                submit(event);
            }
        };
        document.addEventListener('keydown', listener);
        return () => {
            document.removeEventListener('keydown', listener);
        };
    }, [data]);

    useEffect(() => {
        if (googleData && !Cache.get('googleData') && googleData !== 'null') {
            Cache.set('googleData', googleData);
            deleteUrlParams(urlParams, ['googleData']);
        }
        document.title = 'Login | Tessell';
    }, []);

    useEffect(() => {
        if (
            responseStatus === 401 &&
            response &&
            response?.message === PasswordExpiryErrorMessage
        ) {
            setMessage(response?.message);
            setSeverity('error');
            setNotify(true);
            setPasswordExpired(true);
            setDone(true);
        } else if (responseStatus > 201 && response && response?.message?.length) {
            Cache.clear();
            setSubmitted(false);
            setGoogleSubmitted(false);
            setMessage(response?.message);
            setSeverity('error');
            setNotify(true);
        } else if (responseStatus === 200 && response?.accessToken?.length) {
            Cache.set('accessToken', response?.accessToken);
            Cache.set('refreshToken', response?.refreshToken);
            saveResData(response, {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: response?.accessToken,
            });
            // saveUserData(response);
        } else if (
            responseStatus === 200 &&
            !response?.accessToken &&
            response?.tenantUserAttributes?.length > 1
        ) {
            Cache.set('refreshToken', response?.refreshToken);
            saveResData(response, {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: response?.accessToken,
            });
        }
    }, [response, responseStatus]);

    useEffect(() => {
        if (Date.now() < expiryTime && Cache.get('persona') && Cache.get('accessToken')) {
            const headers = {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: Cache.get('accessToken'),
            };
            whoamiCall(headers);
        }
    }, [Cache.get('accessToken')]);

    const [showLinearProgressBar, setShowLinearProgressBar] = useState(false);

    if (passwordExpired) {
        Cache.set('tenant-data', JSON.stringify(multiTenantData));
        return <Redirect push to={{ pathname: '/reset-expired-password', state: data }} />;
    }

    if (
        (googleSubmitted && !redirect) ||
        (submitted && !response?.tenantUserAttributes) ||
        (Date.now() < expiryTime &&
            Cache.get('persona') &&
            Cache.get('accessToken') &&
            !redirect) ||
        googleData
    ) {
        setTimeout(() => setShowLinearProgressBar(true), 500);
        return (
            <Stack sx={classes.body} justifyContent="center" alignItems="center">
                <Stack sx={{ height: '178px' }} alignItems="center">
                    <Zoom in appear timeout={500}>
                        <Stack width={130} height={130} justifyContent="center" alignItems="center">
                            <TessellIcon />
                        </Stack>
                    </Zoom>
                    {showLinearProgressBar && (
                        <Fade in appear easing={{ enter: 'ease-in' }} timeout={{ enter: 300 }}>
                            <Stack width={240} height="8px" mt={5}>
                                <LinearProgress
                                    sx={{
                                        borderRadius: '4px',
                                        color: styles.color.header,
                                        display: 'flex',
                                        flex: '1 1 auto',
                                        backgroundColor: '#eeeeee',
                                        '.MuiLinearProgress-bar': { borderRadius: '4px' },
                                    }}
                                    m={5}
                                />
                            </Stack>
                        </Fade>
                    )}
                </Stack>
            </Stack>
        );
    }

    if (
        response &&
        response.message ===
            'Cognito: User is not yet in active state, current state: NEW_PASSWORD_REQUIRED'
    ) {
        return <Redirect push to="/reset" />;
    }
    if (
        response &&
        response.message ===
            'Cognito: User is not yet in active state, current state: NEW_PASSWORD_REQUIRED'
    ) {
        return <Redirect push to="/reset" />;
    }

    if (done) {
        window.location.href = redirectUrl;
    }

    if (redirect && !multiTenant) {
        if (uri && uri !== 'null' && uri !== '/login') {
            return <Redirect push to={uri} />;
        }
        if (Cache.get('persona') === 'Tessell Operations Administrator') {
            return <Redirect push to="/apps/operations/tasks" />;
        }
        return <Redirect push to="/apps/db-services/my-services" />;
    }

    if (redirect && multiTenant) {
        Cache.set('tenant-data', JSON.stringify(multiTenantData));
        return (
            <Redirect push to={{ pathname: '/tenants', state: { response: multiTenantData } }} />
        );
    }

    const handleMicrosoftLogin = async () => {
        const provider = new OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            prompt: 'select_account',
        });
        provider.addScope('email');

        try {
            await setPersistence(auth, browserLocalPersistence); // Ensure persistence
            signInWithPopup(auth, provider)
                .then((microsoftUserInfo) => {
                    const updatedUserInfo = {
                        ...microsoftUserInfo?.user,
                        email: microsoftUserInfo?.user?.reloadUserInfo?.providerUserInfo?.[0]
                            ?.email,
                    };
                    setUserLoginInfo(updatedUserInfo);
                    navigate('/apps/getting-started/welcome');
                })
                .catch((error) => {
                    setMessage('Invalid credentials');
                    setSeverity('error');
                    setNotify(true);
                });
        } catch (error) {
            console.error('Error during Microsoft login:', error);
        }
    };

    return (
        <Box>
            <Box
                position="sticky"
                sx={{
                    boxShadow: '0px 1px 4px rgba(31, 41, 55, 0.08)',
                }}
            >
                <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    color="white"
                    alignItems="center"
                    ml={5}
                    paddingY={1}
                >
                    <Toolbar disableGutters variant="dense">
                        <TessellLogoWithTextIcon darkBg={false} />
                    </Toolbar>
                </Box>
            </Box>
            <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                justifyContent="center"
                mt="-50px"
            >
                <Stack width="100px" />
                <Stack
                    width={{
                        md: '400px',
                        xs: '300px',
                    }}
                    minWidth={{
                        md: '400px',
                        xs: '300px',
                    }}
                    pt={3}
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    sx={{
                        overflow: 'hidden',
                    }}
                >
                    <Typography
                        color="primary"
                        variant="h4"
                        sx={{
                            size: '40px',
                            lineHeight: '44px',
                            fontWeight: 700,
                        }}
                        mt={2}
                    >
                        Welcome!
                    </Typography>
                    <Typography variant="body1" my={2}>
                        Let’s Tessellate Your Data
                    </Typography>
                    <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="center"
                        width="100%"
                        spacing={1}
                    >
                        <>
                            <Button
                                onClick={() => {
                                    const tenant = window.location.origin;
                                    setRedirectUrl(
                                        `${
                                            window.location.hostname.includes('.tessell.com')
                                                ? oauthUrl.prod
                                                : oauthUrl.dev
                                        }/oauth/google?tenant=${btoa(tenant)}`,
                                    );
                                    setDone(true);
                                }}
                                maxHeight="26px"
                                sx={classes.oauthButtons}
                                disabled
                            >
                                <Stack
                                    height="36px"
                                    width="100%"
                                    direction="row"
                                    justifyContent="space-between"
                                    p={0.5}
                                >
                                    <Stack width="20%">
                                        <GoogleIcon darkBg={false} height={26} />
                                    </Stack>
                                    <Stack width="80%">
                                        <Typography ml={1}>
                                            {googleSubmitted ? (
                                                <Box display="flex" flexDirection="row">
                                                    <Typography sx={{ color: 'white' }} ml={2}>
                                                        Logging In...
                                                    </Typography>
                                                    <CircularProgress
                                                        sx={classes.progress}
                                                        size={32}
                                                        ml={1}
                                                    />
                                                </Box>
                                            ) : (
                                                <Typography>Log In with Google</Typography>
                                            )}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Button>
                        </>
                        <>
                            <Button
                                onClick={handleMicrosoftLogin}
                                // disabled={loginOptions.indexOf('AZURE_AD') === -1}
                                maxHeight="26px"
                                sx={classes.oauthButtons}
                                mt={1}
                            >
                                <Stack
                                    height="36px"
                                    width="100%"
                                    direction="row"
                                    justifyContent="space-between"
                                    p={0.5}
                                >
                                    <Stack width="20%">
                                        <MicrosoftIcon height={26} />
                                    </Stack>
                                    <Stack width="80%">
                                        <Typography ml={1}>Log in with Microsoft</Typography>
                                    </Stack>
                                </Stack>
                            </Button>
                        </>
                        <>
                            <Button
                                onClick={() => {
                                    getMSlogin().then((res) => {
                                        if (res?.url && res.url.length) {
                                            window.open(res?.url, '_self');
                                        }
                                    });
                                }}
                                maxHeight="26px"
                                sx={classes.oauthButtons}
                                disabled={loginOptions.indexOf('OKTA') === -1}
                            >
                                <Stack
                                    height="36px"
                                    width="100%"
                                    direction="row"
                                    justifyContent="space-between"
                                    p={0.5}
                                >
                                    <Stack width="20%">
                                        <OktaShortIcon width="20px" height={26} />
                                    </Stack>
                                    <Stack width="80%">
                                        <Typography justifyContent="center">
                                            Log in with Okta
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Button>
                        </>
                    </Stack>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                        width="100%"
                        mt={2}
                    >
                        <Box
                            sx={{ border: ' 1px solid #EEEEEE', height: '0px', width: '50%' }}
                            m={1.5}
                        />
                        <Typography color="#EEE">OR</Typography>
                        <Box
                            sx={{ border: ' 1px solid #EEEEEE', height: '0px', width: '50%' }}
                            m={1.5}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" width="100%">
                        <TessellInput
                            autoFocus
                            label="Email"
                            size="small"
                            sx={{
                                width: '100%',
                            }}
                            placeholder="Email"
                            value={data.emailId}
                            onChange={(e) => updateValue(e, 'emailId')}
                            disabled={submitted}
                        />

                        <Stack direction="row" alignItems="space-between" width="100%" mt={2}>
                            <Typography
                                sx={{
                                    ...styles.textBoxLabel,
                                }}
                                mb={1}
                                width="90%"
                            >
                                Password
                            </Typography>
                            <Typography
                                sx={{
                                    ...styles.textBoxLabel,
                                    color: styles.color.header,
                                    cursor: 'pointer',
                                }}
                                onClick={() => {
                                    setShowForgot(true);
                                }}
                            >
                                Forgot
                            </Typography>
                        </Stack>
                        <TextField
                            data-private
                            size="small"
                            placeholder="Choose a password"
                            type={showPassword ? 'text' : 'password'}
                            value={data.password}
                            onChange={(e) => updateValue(e, 'password')}
                            disabled={submitted}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={(event) => {
                                                event.preventDefault();
                                            }}
                                            color="primary"
                                            size="large"
                                        >
                                            {showPassword ? (
                                                <Visibility color="primary" />
                                            ) : (
                                                <VisibilityOff />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" mt={3} width="100%">
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={submit}
                            fullWidth
                            // sx={classes.login}
                        >
                            {submitted ? (
                                <>
                                    <CircularProgress sx={classes.progress} size={24} ml={2} />
                                    <Typography variant="subtitle1">Logging In...</Typography>
                                </>
                            ) : (
                                <Typography variant="subtitle1">Log In</Typography>
                            )}
                        </Button>
                    </Box>
                </Stack>

                <Stack
                    display="block"
                    direction="row"
                    // pt={5}
                    width="auto"
                    sx={{
                        overflow: 'hidden',
                        position: 'relative',
                        zoom: 1.2,
                    }}
                >
                    <LoginBanner darkBg={false} />
                </Stack>
            </Stack>
            <Notification
                notify={notify}
                setNotify={setNotify}
                message={message}
                severity={severity}
            />
            <ForgotPasswordDialog
                show={showForgot}
                setShow={setShowForgot}
                setNotify={setNotify}
                setMessage={setMessage}
                setSeverity={setSeverity}
            />
        </Box>
    );
}
