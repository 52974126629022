import Cache from 'tools/Cache';
import SupportUtil from 'tools/SupportUtil';
import HttpStatusCodeEnum from 'types/HttpStatusCodeEnum';

export const checkIfUnauthorized = (error) => {
    if (error?.response && error?.response?.status === HttpStatusCodeEnum.UNAUTHORIZED) {
        // Perform logout or redirect to the login page
        Cache.clear();
        SupportUtil.reset();
        // Redirect to login page
        window.location.href = '/login';
    }
};
