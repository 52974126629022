/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { CircularProgress, Box } from '@mui/material';

// export type LoadingScreenPropType = {
//     isLoading: boolean,
//     children: any,
// }

export default function LoadingOverlay(props) {
    const {
        isLoading,
        children,
        containerStyles,
        showChildrenWhileLoading = true,
        loaderSize = 40,
        classNames,
    } = props;
    // Show children either when the loading stops or when the showChildrenWhileLoading flag is true.
    const showChildren = showChildrenWhileLoading || !isLoading;
    return (
        <Box position="relative" height="100%" width="100%" sx={containerStyles}>
            {isLoading && (
                <Box
                    position="absolute"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                    top="50%"
                    right="50%"
                    className={classNames?.overlay}
                >
                    <CircularProgress
                        style={props.loadingColor ? { color: '#fff' } : {}}
                        size={loaderSize || 40}
                    />
                </Box>
            )}
            {showChildren && children}
        </Box>
    );
}
