export enum AppsForFeaturesEnum {
    'Storages' = 'Storages',
}

export enum StorageAppFeatureFlagEnum {
    'STORAGE_FSX_NETAPP' = 'storage-fsx-netapp',
}

export enum FeatureFlagEnum {
    Servers = 'Servers',
    Provisioning = 'Provisioning',
    MyServices = 'My Services',
    MonitoringInfras = 'Monitoring Perf Insights Infras',
    MultiDisk = 'multi-disk',
    Timezone = 'timezone',
    MssqlHpc = 'mssql-hpc-provisioning',
    CustomiseNetworkCreation = 'customize-network-creation',
    PerfInsightsInfra = 'manage-monitoring-perf-insights-infra', // Monitoring Perf Insight Flag
    DrSyncAndAccessModeConfig = 'enable-dr-config', // All flag for 5 node ha - Access Mode and Sync Mode
    MultiDRAllowInSameRegion = 'enable-multi-dr-in-same-region', // Enable DR in the same region
    DRAllowInPrimaryRegionForHA = 'enable-dr-in-primary-region-for-ha', // Enable Dr in primary region for HA
    ServerComputeResize = 'server-resize',
    ManageServer = 'manage-servers',
    ShowDetailedSummaryNotification = 'show-detailed-summary-notification',
}

export enum FeatureFlagEngineTypeEnum {
    ORACLE = 'oracle',
    MYSQL = 'mysql',
    POSTGRES = 'postgres',
    MSSQL = 'mssql',
}

export enum EngineFullFormLowercaseEnum {
    'oracle' = 'oracle',
    'mysql' = 'mysql',
    'postgres' = 'postgresql',
    'mssql' = 'sqlserver',
}
