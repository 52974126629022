/*
 *
 * Copyright © Tessell Inc, 2021. All rights reserved.
 *
 *     Date            Author                  Comment
 *   --------------------------------------------------
 *     8/19/2021     bakul.banthia         Created
 *
 */
import PropTypes from 'prop-types';
import React from 'react';

export default function ElasticsearchIcon(props) {
    const { className } = props;
    return (
        <svg
            className={className}
            viewBox="0 0 74 77"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M55 25H34.1C34.2 25.5 34.4 26 34.5 26.4C35.2 28.8 35.6 31.3 35.8 33.9C35.9 34.9 35.9 36 35.9 37C35.9 38 35.9 39.1 35.8 40.1C35.6 42.7 35.2 45.2 34.5 47.6C34.4 48.1 34.2 48.6 34.1 49H55C61.6 49 66.9 43.6 66.9 37C66.9 30.4 61.6 25 55 25Z"
                fill="#00A9E5"
            />
            <path
                d="M35.8 40.1C35.9 39.1 35.9 38 35.9 37C35.9 36 35.9 34.9 35.8 33.9C35.6 31.3 35.2 28.8 34.5 26.4C34.4 25.9 34.2 25.4 34.1 25H1.8C0.599995 28.8 0 32.8 0 37C0 41.2 0.599995 45.2 1.8 49H34.1C34.2 48.5 34.4 48 34.5 47.6C35.2 45.2 35.6 42.7 35.8 40.1Z"
                fill="#353535"
            />
            <path
                d="M58.6 53H32.6C31.8 54.8 30.9 56.5 29.9 58.1C28.4 60.6 26.6 62.8 24.6 64.9C23.9 65.6 23.2 66.2 22.5 66.9C21.1 68.2 19.5 69.3 17.9 70.4C24.2 74.6 31.8 77 39.9 77C53.8 77 66 69.9 73.2 59.2C69.5 55.4 64.3 53 58.6 53Z"
                fill="#00BFB3"
            />
            <path
                d="M29.9 58.1C30.9 56.5 31.8 54.8 32.6 53H3.29999C6.39999 60.1 11.5 66.2 18 70.4C19.6 69.3 21.1 68.2 22.6 66.9C23.3 66.3 24 65.6 24.7 64.9C26.6 62.9 28.4 60.6 29.9 58.1Z"
                fill="#019B8F"
            />
            <path
                d="M24.6 9.09998C23.9 8.39998 23.2 7.79998 22.5 7.09998C21.1 5.79998 19.5 4.69998 17.9 3.59998C11.5 7.79998 6.40001 13.9 3.20001 21H32.5C31.7 19.2 30.8 17.5 29.8 15.9C28.4 13.4 26.6 11.1 24.6 9.09998V9.09998Z"
                fill="#F9B110"
            />
            <path
                d="M39.9 0C31.8 0 24.2 2.4 17.9 6.6C19.5 7.7 21 8.8 22.5 10.1C23.2 10.7 23.9 11.4 24.6 12.1C26.6 14.2 28.4 16.4 29.9 18.9C30.9 20.5 31.8 22.2 32.6 24H58.6C64.3 24 69.5 21.6 73.2 17.8C66 7.1 53.8 0 39.9 0V0Z"
                fill="#FED10A"
            />
        </svg>
    );
}

ElasticsearchIcon.propTypes = {
    className: PropTypes.string.isRequired,
};
