import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFeatureFlags, featureFlagsActions } from 'redux/featureFlags.slice';
import { FeatureFlagEnum } from 'schemas/enums/featureFlagEnum';
import Cache from 'tools/Cache';

export interface relationalDbObj {
    oracle: boolean;
    mysql: boolean;
    postgresql: boolean;
    sqlserver: boolean;
}

export type FeatureFlagsOperators = {
    serversApp: {
        isServersAppAvailable: boolean;
        updateIsServersAppAvailable: (arg: boolean) => void;
        isServersResizeComputeAppAvailable: boolean;
        updateIsServersResizeComputeAppAvailable: (arg: boolean) => void;
    };
    multiDiskProvisioning: {
        isMultiDiskAvailable: boolean;
        updateIsMultiDiskAvailable: (arg: boolean) => void;
    };
    timezone: {
        isTimezoneFeatureAvailable: {
            relationalDB: relationalDbObj;
        };
        updateIsTimezoneFeatureAvailable: (arg: { relationalDB: relationalDbObj }) => void;
    };
    advanceStorage: {
        isAWSFSxFileSysAvailable: boolean;
        updateIsAWSFSxFileSysAvailable: (arg: boolean) => void;
    };
    provisioningApp: {
        isMssqlHpcProvisioningAvailable: boolean;
        updateIsMssqlHpcAvailable: (arg: boolean) => void;
    };
    monitoringInfraApp: {
        isPerfInsightsInfraAvailable: boolean;
        updateIsPerfInsightsAvailable: (arg: boolean) => void;
    };
    instances: {
        isDrSyncAndAccessModeConfig: boolean;
        updateIsDrSyncAndAccessModeConfig: (arg: boolean) => void;

        isMultiDRAllowInSameRegion: boolean;
        updateIsMultiDRAllowInSameRegion: (arg: boolean) => void;

        isDRAllowInPrimaryRegionForHA: boolean;
        updateIsDRAllowInPrimaryRegionForHA: (arg: boolean) => void;
    };
    notifications: {
        showDetailedSummaryNotification: boolean;
        updateShowDetailedSummaryNotification: (arg: boolean) => void;
    };
};
// ? whoami api call gives feature flag for isServersAppAvailable - key = 'oracle-multi-service-provisioning'

const useFeatureFlags = (): FeatureFlagsOperators => {
    const dispatch = useDispatch();

    const featureFlags = useSelector(selectFeatureFlags);

    const updateIsServersAppAvailable = (isServersAppAvailableArg: boolean) => {
        dispatch(featureFlagsActions.updateIsServerAvailable(isServersAppAvailableArg));
    };
    const updateIsServersResizeComputeAppAvailable = (
        isServersResizeComputeAppAvailable: boolean,
    ) => {
        dispatch(
            featureFlagsActions.updateIsServerResizeAvailable(isServersResizeComputeAppAvailable),
        );
    };
    const updateIsMultiDiskAvailable = (isMultiDiskAvailableArg: boolean) => {
        dispatch(
            featureFlagsActions.updateIsMultiDiskProvisioningAvailable(isMultiDiskAvailableArg),
        );
    };

    const updateIsTimezoneFeatureAvailable = (isTimezoneFeatureAvailableArg: {
        relationalDB: relationalDbObj;
    }) => {
        dispatch(
            featureFlagsActions.updateIsTimezoneFeatureAvailable(isTimezoneFeatureAvailableArg),
        );
    };

    const updateIsMssqlHpcAvailable = (isMssqlHpcProvisioningAvailableArg: boolean) => {
        dispatch(
            featureFlagsActions.updateIsMssqlHpcProvisioningAvailable(
                isMssqlHpcProvisioningAvailableArg,
            ),
        );
    };
    const updateIsPerfInsightsAvailable = (isPerfInsightsInfraAvailable: boolean) => {
        dispatch(
            featureFlagsActions.updateIsPerfInsightsInfraAvailable(isPerfInsightsInfraAvailable),
        );
    };
    const updateIsDrSyncAndAccessModeConfig = (isDrSyncAndAccessModeConfig: boolean) => {
        dispatch(
            featureFlagsActions.updateIsDrSyncAndAccessModeConfigAvailable(
                isDrSyncAndAccessModeConfig,
            ),
        );
    };

    const updateIsMultiDRAllowInSameRegion = (isMultiDRAllowInSameRegion: boolean) => {
        dispatch(
            featureFlagsActions.updateIsMultiDRAllowInSameRegionAvailable(
                isMultiDRAllowInSameRegion,
            ),
        );
    };
    const updateIsDRAllowInPrimaryRegionForHA = (isDRAllowInPrimaryRegionForHA: boolean) => {
        dispatch(
            featureFlagsActions.updateIsDRAllowInPrimaryRegionForHAAvailable(
                isDRAllowInPrimaryRegionForHA,
            ),
        );
    };

    // const updateIsAWSFSxFileSysAvailable = (isAWSFSxFileSysAvailable: boolean) => {
    //     dispatch(featureFlagsActions.updateIsAWSFSxFileSysAvailable(isAWSFSxFileSysAvailable));
    // };

    const isAWSFSxFileSysAvailable =
        JSON.parse(Cache.get('features'))?.Storages?.includes('storage-fsx-netapp') || false;

    const showDetailedSummaryNotification =
        JSON.parse(Cache.get(FeatureFlagEnum.ShowDetailedSummaryNotification)) || false;

    const updateShowDetailedSummaryNotification = (showDetailedSummaryNotification: boolean) => {
        Cache.set(FeatureFlagEnum.ShowDetailedSummaryNotification, showDetailedSummaryNotification);
    };

    return {
        serversApp: {
            isServersAppAvailable: featureFlags?.isServersAppAvailable,
            updateIsServersAppAvailable,
            isServersResizeComputeAppAvailable: featureFlags?.isServersResizeComputeAppAvailable,
            updateIsServersResizeComputeAppAvailable,
        },
        multiDiskProvisioning: {
            isMultiDiskAvailable: featureFlags?.isMultiDiskProvisioningAvailable,
            updateIsMultiDiskAvailable,
        },
        timezone: {
            isTimezoneFeatureAvailable: featureFlags?.isTimezoneFeatureAvailable,
            updateIsTimezoneFeatureAvailable,
        },
        provisioningApp: {
            isMssqlHpcProvisioningAvailable: featureFlags?.isMssqlHpcProvisioningAvailable,
            updateIsMssqlHpcAvailable,
        },
        monitoringInfraApp: {
            isPerfInsightsInfraAvailable: featureFlags?.isPerfInsightsInfraAvailable,
            updateIsPerfInsightsAvailable,
        },
        instances: {
            isMultiDRAllowInSameRegion: featureFlags?.isMultiDRAllowInSameRegion,
            updateIsDrSyncAndAccessModeConfig,
            isDRAllowInPrimaryRegionForHA: featureFlags?.isDRAllowInPrimaryRegionForHA,
            updateIsMultiDRAllowInSameRegion,
            isDrSyncAndAccessModeConfig: featureFlags?.isDrSyncAndAccessModeConfig,
            updateIsDRAllowInPrimaryRegionForHA,
        },
        advanceStorage: {
            isAWSFSxFileSysAvailable,
            updateIsAWSFSxFileSysAvailable: () => {},
        },
        notifications: {
            showDetailedSummaryNotification,
            updateShowDetailedSummaryNotification,
        },
    };
};
export default useFeatureFlags;
