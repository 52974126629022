/* eslint-disable no-param-reassign */

import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './configureStore';
import { relationalDbObj } from 'customHooks/useFeatureFlags';

export interface FeatureFlags {
    isServersAppAvailable: boolean;
    isServersResizeComputeAppAvailable: boolean;
    isMultiDiskProvisioningAvailable?: boolean;
    isTimezoneFeatureAvailable?: { relationalDB: relationalDbObj };
    isMssqlHpcProvisioningAvailable: boolean;
    isPerfInsightsInfraAvailable: boolean;
    isDrSyncAndAccessModeConfig?: boolean;
    isMultiDRAllowInSameRegion?: boolean;
    isDRAllowInPrimaryRegionForHA?: boolean;
    isAWSFSxFileSysAvailable: boolean;
}

const initialState: FeatureFlags = {
    isServersAppAvailable: false,
    isServersResizeComputeAppAvailable: false,
    isMultiDiskProvisioningAvailable: false,
    isTimezoneFeatureAvailable: {
        relationalDB: {
            oracle: false,
            mysql: false,
            postgresql: false,
            sqlserver: false,
        },
    },
    isMssqlHpcProvisioningAvailable: false,
    isPerfInsightsInfraAvailable: false,
    isDrSyncAndAccessModeConfig: false,
    isMultiDRAllowInSameRegion: false,
    isDRAllowInPrimaryRegionForHA: false,
    isAWSFSxFileSysAvailable: false,
};

// slice
export const featureFlagsSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        updateIsServerAvailable: (state, action: PayloadAction<any>) => {
            state.isServersAppAvailable = action.payload;
        },
        updateIsServerResizeAvailable: (state, action: PayloadAction<any>) => {
            state.isServersResizeComputeAppAvailable = action.payload;
        },
        updateIsMultiDiskProvisioningAvailable: (state, action: PayloadAction<any>) => {
            state.isMultiDiskProvisioningAvailable = action.payload;
        },
        updateIsTimezoneFeatureAvailable: (state, action: PayloadAction<any>) => {
            state.isTimezoneFeatureAvailable = action.payload;
        },
        updateIsMssqlHpcProvisioningAvailable: (state, action: PayloadAction<any>) => {
            state.isMssqlHpcProvisioningAvailable = action.payload;
        },
        updateIsPerfInsightsInfraAvailable: (state, action: PayloadAction<any>) => {
            state.isPerfInsightsInfraAvailable = action.payload;
        },
        updateIsDrSyncAndAccessModeConfigAvailable: (state, action: PayloadAction<any>) => {
            state.isDrSyncAndAccessModeConfig = action.payload;
        },
        updateIsMultiDRAllowInSameRegionAvailable: (state, action: PayloadAction<any>) => {
            state.isMultiDRAllowInSameRegion = action.payload;
        },
        updateIsDRAllowInPrimaryRegionForHAAvailable: (state, action: PayloadAction<any>) => {
            state.isDRAllowInPrimaryRegionForHA = action.payload;
        },
        updateIsAWSFSxFileSysAvailable: (state, action: PayloadAction<any>) => {
            state.isAWSFSxFileSysAvailable = action.payload;
        },
    },
});

// Actions
export const featureFlagsActions = {
    updateIsServerAvailable: featureFlagsSlice.actions.updateIsServerAvailable,
    updateIsServerResizeAvailable: featureFlagsSlice.actions.updateIsServerResizeAvailable,
    updateIsMultiDiskProvisioningAvailable:
        featureFlagsSlice.actions.updateIsMultiDiskProvisioningAvailable,
    updateIsTimezoneFeatureAvailable: featureFlagsSlice.actions.updateIsTimezoneFeatureAvailable,
    updateIsMssqlHpcProvisioningAvailable:
        featureFlagsSlice.actions.updateIsMssqlHpcProvisioningAvailable,
    updateIsPerfInsightsInfraAvailable:
        featureFlagsSlice.actions.updateIsPerfInsightsInfraAvailable,
    updateIsDrSyncAndAccessModeConfigAvailable:
        featureFlagsSlice.actions.updateIsDrSyncAndAccessModeConfigAvailable,
    updateIsMultiDRAllowInSameRegionAvailable:
        featureFlagsSlice.actions.updateIsMultiDRAllowInSameRegionAvailable,
    updateIsDRAllowInPrimaryRegionForHAAvailable:
        featureFlagsSlice.actions.updateIsDRAllowInPrimaryRegionForHAAvailable,
    updateIsAWSFSxFileSysAvailable: featureFlagsSlice.actions.updateIsAWSFSxFileSysAvailable,
};

// Selectors
export const selectFeatureFlags = (state: RootState) => state.featureFlagsReducer;

// Reducer
export default featureFlagsSlice.reducer;
