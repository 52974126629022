import clsx from 'clsx';
import { useStyles } from './utils';

export default function RefreshIconMonotone(props) {
    const { className } = props;
    const iconStyle = useStyles(props);

    return (
        <svg
            className={clsx({ [className]: !!className })}
            style={iconStyle}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24" height="24" fill="white" fillOpacity="0.01" />
            <path
                d="M9.00001 7.50027H5.08501C6.05502 6.00952 7.48082 4.87215 9.14987 4.25771C10.8189 3.64326 12.6419 3.58465 14.3469 4.09061C16.052 4.59657 17.5479 5.64 18.6117 7.06537C19.6755 8.49074 20.2501 10.2217 20.25 12.0003H21.75C21.7521 9.99439 21.1354 8.03669 19.9841 6.3941C18.8328 4.75152 17.2029 3.50401 15.3166 2.82168C13.4304 2.13934 11.3796 2.05539 9.44384 2.58128C7.50812 3.10716 5.78171 4.21728 4.50001 5.76027V3.00027H3.00001V9.00027H9.00001V7.50027Z"
                fill="#08080D"
            />
            <path
                d="M15 16.5003H18.915C17.945 17.991 16.5192 19.1284 14.8501 19.7428C13.1811 20.3573 11.3582 20.4159 9.65308 19.9099C7.948 19.404 6.45209 18.3605 5.38832 16.9352C4.32455 15.5098 3.74989 13.7788 3.75001 12.0003H2.25001C2.24794 14.0062 2.8646 15.9639 4.0159 17.6064C5.1672 19.249 6.79711 20.4965 8.68337 21.1789C10.5696 21.8612 12.6204 21.9452 14.5562 21.4193C16.4919 20.8934 18.2183 19.7833 19.5 18.2403V21.0003H21V15.0003H15V16.5003Z"
                fill="#08080D"
            />
        </svg>
    );
}
