import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import Cache from 'tools/Cache';
import useInterval from 'tools/useInterval';
import { REFRESH_TOKEN_AFTER } from 'constants/app.constants';
import { NetworkConnectionContext } from 'network-context/NetworkConnectionContext';

interface UserLoginInfoProps {
    children: any;
}

interface UserPermissionsContextType {
    availableApps: string[];
    isLoading: boolean;
}

export const UserLoginInfoContext = createContext({});
const SetUserLoginInfoContext = createContext((user: any) => {});

export const UserPermissionsContext = createContext<UserPermissionsContextType>({
    availableApps: [],
    isLoading: true,
});

export const useUserLoginInfoContext = (): any => {
    const userLoginInfo = useContext(UserLoginInfoContext);

    return Object.keys(userLoginInfo)?.length > 0
        ? userLoginInfo
        : JSON.parse(Cache.get('loginInfo') || '{}');
};
export const useSetUserLoginInfoContext = () => {
    const setUserLoginInfo = useContext(SetUserLoginInfoContext);

    return setUserLoginInfo;
};

const refresh = async () => {
    try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();
        cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
            const { idToken, refreshToken, accessToken } = session;
            Cache.set('idToken', idToken?.jwtToken);
            Cache.set('refreshToken', idToken?.jwtToken);
            Cache.set('accessToken', accessToken?.jwtToken);
        });
    } catch (e) {
        console.log('Unable to refresh Token', e);
    }
};

export const UserLoginInfo = (props: UserLoginInfoProps) => {
    const { children } = props;
    const [loginInfo, setLoginInfo] = useState<any>({});
    const [availableApps, setAvailableApps] = useState([]);
    const [isPermissionsLoading, setIsPermissionsLoading] = useState(false);
    const { fetchDataByKeyValue } = useContext(NetworkConnectionContext);

    // useInterval(() => {
    //     refresh();
    // }, REFRESH_TOKEN_AFTER);

    const setUserLoginInfo = (newLoginInfo?: any) => {
        setLoginInfo(newLoginInfo);
        Cache.set('loginInfo', JSON.stringify(newLoginInfo));
        Cache.set('userName', newLoginInfo?.email);
        if (newLoginInfo?.accessToken) {
            Cache.set('idToken', newLoginInfo?.accessToken);
            Cache.set('refreshToken', newLoginInfo?.accessToken);
            Cache.set('accessToken', newLoginInfo?.accessToken);
            setIsPermissionsLoading(true);
        }
    };

    const userLoginInfo = useUserLoginInfoContext();

    const currentLoginInfo = useMemo(() => {
        return userLoginInfo && Object.keys(userLoginInfo).length > 0 ? userLoginInfo : loginInfo;
    }, [userLoginInfo, loginInfo]);

    useEffect(() => {
        if (currentLoginInfo?.email) {
            setLoginInfo(currentLoginInfo);
            setIsPermissionsLoading(true);
            fetchDataByKeyValue('users', 'email', currentLoginInfo?.email).then((res) => {
                setLoginInfo((prev) => ({ ...prev, subscriptionNames: res[0]?.subscriptionNames }));
                fetchDataByKeyValue('personas', 'id', res[0]?.personaId).then((res) => {
                    const appNames = res[0]?.apps?.map((app) => app?.name);
                    setAvailableApps(appNames);
                    setIsPermissionsLoading(false);
                });
            });
        }
    }, [currentLoginInfo?.email]);

    return (
        <UserPermissionsContext.Provider
            value={{
                availableApps: availableApps || [],
                isLoading: isPermissionsLoading,
            }}
        >
            <SetUserLoginInfoContext.Provider value={setUserLoginInfo}>
                <UserLoginInfoContext.Provider value={loginInfo}>
                    {children}
                </UserLoginInfoContext.Provider>
            </SetUserLoginInfoContext.Provider>
        </UserPermissionsContext.Provider>
    );
};
